<template>
    <UDropdown :items="items"
        :popper="{ placement: props.type == 'notebook' ? 'right-start' : 'left-start', arrow: true, strategy: 'absolute' }"
        :ui="{ container: 'z-50', width: 'w-max', ring: 'ring-primary-400', arrow: { ring: 'before:ring-primary-400' } }">
        <button type="button" class="whitespace-nowrap" :class="buttonBadgeClass">
            <span class="px-2" v-if="props.type == 'notebook'">Run the notebook</span>
            <span class="px-2" v-else>Deploy</span>
            <Icon name="heroicons:bars-3-16-solid" class="inline ml-1 align-middle" />
        </button>
        <template #item="{ item }" class="text-left">
            <Icon :name="item.icon" class="flex-shrink-0 h-4 w-4 text-gray-800" />
            <span class="truncate font-medium">{{ item.label }}</span>
        </template>
    </UDropdown>
</template>

<script setup>
const props = defineProps({
    buttons: Object,
    type: Object,
})
const buttonBadgeClass = computed(() => {
    return (props.type == 'notebook')
        ? 'border-2 border-primary-500 flex items-center px-4 pr-3 rounded-lg text-primary-500 inline hover:bg-gray-200 transition py-[0.45rem] px-4'
        : 'pw-button--primary p-[0.45rem] px-4 rounded-lg text-white inline';
});
const items = ref([])
if (props.buttons?.project && props.type == 'docker') {
    items.value.push([{
        label: 'Download from GitHub',
        icon: 'mdi:github',
        to: props.buttons.project,
        target: "_blank"
    }])
}
if (props.buttons?.notebook && props.type == 'notebook') {
    items.value.push([{
        label: 'Download from GitHub',
        icon: 'mdi:github',
        to: props.buttons.notebook,
        target: "_blank"
    }])
    items.value.push([{
        label: 'Run with Colab',
        icon: 'simple-icons:googlecolab',
        to: props.buttons.colab,
        target: "_blank"
    }])
    items.value.push([{
        label: 'Run with Lightning AI',
        icon: 'mdi:lightning-bolt',
        to: props.buttons.studio,
        target: "_blank"
    }])
}
if (props.buttons?.docker && props.type == 'docker') {
    items.value.push([{
        label: 'Deploy to GCP',
        icon: 'simple-icons:googlecloud',
        to: '/developers/user-guide/deployment/gcp-deploy?target=' + props.buttons.docker
    }])
    items.value.push([{
        label: 'Deploy to AWS',
        icon: 'simple-icons:awsfargate',
        to: '/developers/user-guide/deployment/aws-fargate-deploy?target=' + props.buttons.docker
    }])
    items.value.push([{
        label: 'Deploy to Azure',
        icon: 'material-symbols:cloud',
        to: '/developers/user-guide/deployment/azure-aci-deploy?target=' + props.buttons.docker
    }])
    items.value.push([{
        label: 'Deploy with Render',
        icon: 'material-symbols:cloud',
        to: '/developers/user-guide/deployment/render-deploy?target=' + props.buttons.docker
    }])
}
</script>
